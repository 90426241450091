<template>
  <div class="reset-form">
    <div class="reset-form__container">
      <div class="reset-form__content">
        <!-- Welcome section -->
        <div class="reset-form__welcome">
          <div class="reset-form__welcome-header">
            <h2 class="reset-form__welcome-title">Reset Password</h2>
            <span class="reset-form__key-emoji">🔑</span>
          </div>
          <p class="reset-form__welcome-subtitle">Enter your email to reset your password</p>
        </div>

        <!-- Success message -->
        <q-banner v-if="successMessage" class="reset-form__success bg-positive text-white">
          <template v-slot:avatar>
            <q-icon name="check_circle" color="white" />
          </template>
          {{ successMessage }}
        </q-banner>

        <!-- Notification message with primary color scheme -->
        <q-banner v-if="warningMessage" class="reset-form__notification">
          <template v-slot:avatar>
            <q-icon name="mail_outline" color="main-btn-color" />
          </template>
          {{ warningMessage }}
        </q-banner>

        <!-- Error message -->
        <q-banner v-if="error" class="reset-form__error bg-negative text-white">
          <template v-slot:avatar>
            <q-icon name="error" color="white" />
          </template>
          {{ error }}
        </q-banner>

        <!-- Reset form -->
        <q-form @submit.prevent="handleResetRequest" class="reset-form__form" ref="resetForm">
          <div class="reset-form__form-group">
            <label for="email" class="reset-form__label">Email</label>
            <q-input
              outlined
              v-model="email"
              id="email"
              type="email"
              class="reset-form__input"
              hide-bottom-space
              :rules="[val => !!val || 'Email is required', val => isValidEmail(val) || 'Please enter a valid email address']"
            >
              <template v-slot:prepend>
                <q-icon name="email" color="secondary-text-color" />
              </template>
            </q-input>
          </div>

          <q-btn flat type="submit" :loading="isLoading" class="reset-form__submit-btn" :label="isLoading ? 'Sending...' : 'Send Reset Link'" no-caps />
        </q-form>

        <div class="reset-form__back-to-login">
          <span>Remember your password? </span>
          <a href="#" @click.prevent="$emit('back-to-login')" class="reset-form__back-link"> Back to login </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthApi } from '@api/index';

export default {
  name: 'ResetForm',

  data() {
    return {
      email: '',
      error: null,
      successMessage: null,
      warningMessage: null,
      isLoading: false
    };
  },

  methods: {
    /**
     * Handles the password reset request
     * Sends the reset request to the API and displays appropriate messages
     * @async
     */
    async handleResetRequest() {
      // Reset previous messages
      this.error = null;
      this.successMessage = null;
      this.warningMessage = null;

      // Validate form
      const isValid = await this.$refs.resetForm.validate();
      if (!isValid) {
        return;
      }

      this.isLoading = true;

      try {
        // Call the API
        const response = await AuthApi.resetPassword(this.email);

        // Check response structure and log it to help debug
        console.log('Reset password response:', response);

        if (response && response.message) {
          this.warningMessage = response.message;
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Reset password error:', error);

        // Handle different error types
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorMessage = error.response.data?.message || error.response.data?.error || 'Server error. Please try again later.';
          this.error = errorMessage;
        } else if (error.request) {
          // The request was made but no response was received
          this.error = 'No response from server. Please check your internet connection.';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.error = 'An error occurred. Please try again.';
        }
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Validates email format using regex pattern
     * @param {string} email - Email address to validate
     * @returns {boolean} True if email is valid, false otherwise
     */
    isValidEmail(email) {
      if (!email) return false;
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(email);
    }
  },

  emits: ['back-to-login']
};
</script>

<style lang="scss" scoped>
.reset-form {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 80px;
  }

  &__container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--secondary-bg-color);
    padding: 32px;
    border-radius: 8px;

    @media (max-width: 768px) {
      border-radius: 0;
      padding: 32px 24px;
      min-height: calc(100vh - 80px);
      justify-content: flex-start;
      padding-top: 48px;
    }
  }

  &__welcome {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    align-items: flex-start;
  }

  &__welcome-header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__key-emoji {
    font-size: 24px;
    line-height: 1;
  }

  &__welcome-title {
    @include mona-sans-font($size: 24px, $bolded: true, $color: var(--main-text-color));
    margin: 0;
    text-align: left;
  }

  &__welcome-subtitle {
    @include inter-font($size: 16px, $color: var(--secondary-text-color));
    margin: 0;
    text-align: left;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &__label {
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    text-align: left;
    margin-bottom: 2px;
  }

  &__input {
    width: 100%;

    :deep(.q-field__control) {
      height: 48px;
    }

    :deep(.q-field__native) {
      @include inter-font($size: 16px, $color: var(--main-text-color));
      padding-top: 0;
      padding-bottom: 0;
    }

    :deep(.q-field__label) {
      display: none;
    }

    :deep(.q-field__marginal) {
      color: var(--secondary-text-color);
    }

    &.q-field--focused {
      :deep(.q-field__control) {
        border-color: var(--main-btn-color);
      }
    }
  }

  &__submit-btn {
    width: 100%;
    height: 48px;
    background-color: var(--main-btn-color);
    margin-top: 8px;
    @include inter-font($size: 16px, $bolded: true, $color: var(--main-text-color));

    &:hover {
      background-color: darken(#fecb30, 5%);
    }
  }

  &__back-to-login {
    text-align: center;
    margin-top: 16px;
    @include inter-font($size: 14px, $color: var(--secondary-text-color));
  }

  &__back-link {
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__notification {
    border-radius: 4px;
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    background-color: rgba(254, 203, 48, 0.1); /* Lighter version of main-btn-color */
    border-left: 4px solid var(--main-btn-color);
    padding: 16px;
    padding-left: 12px;
  }

  &__success,
  &__error {
    border-radius: 4px;
    @include inter-font($size: 14px);
  }
}
</style>
