<template>
  <div class="login-form">
    <div class="login-form__container">
      <div class="login-form__content">
        <!-- Welcome section -->
        <div class="login-form__welcome">
          <div class="login-form__welcome-header">
            <h2 class="login-form__welcome-title">Welcome back</h2>
            <span class="login-form__camera-emoji">📸</span>
          </div>
          <p class="login-form__welcome-subtitle">Sign in to continue</p>
        </div>

        <!-- Error message -->
        <q-banner v-if="error" class="login-form__error bg-negative-light text-negative">
          <template v-slot:avatar>
            <q-icon name="error" color="negative" />
          </template>
          {{ error }}
        </q-banner>

        <!-- Login form with custom labeled inputs -->
        <q-form @submit.prevent="loginWithCredentials" class="login-form__form">
          <div class="login-form__form-group">
            <label for="email" class="login-form__label">Email</label>
            <q-input
              outlined
              v-model="credentials.email"
              id="email"
              type="email"
              class="login-form__input"
              hide-bottom-space
              lazy-rules
              :rules="[val => !!val || 'Email is required', val => isValidEmail(val) || 'Please enter a valid email address']"
            >
              <template v-slot:prepend>
                <q-icon name="email" color="secondary-text-color" />
              </template>
            </q-input>
          </div>

          <div class="login-form__form-group">
            <label for="password" class="login-form__label">Password</label>
            <q-input
              outlined
              v-model="credentials.password"
              id="password"
              :type="showPassword ? 'text' : 'password'"
              class="login-form__input"
              hide-bottom-space
              lazy-rules
              :rules="[val => !!val || 'Password is required']"
            >
              <template v-slot:prepend>
                <q-icon name="lock" color="secondary-text-color" />
              </template>
              <template v-slot:append>
                <q-icon :name="showPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer" color="secondary-text-color" @click="showPassword = !showPassword" />
              </template>
            </q-input>
          </div>

          <div class="login-form__forgot-password">
            <a href="#" class="login-form__forgot-link" @click.prevent="$emit('forgot-password')">Forgot Password?</a>
          </div>

          <q-btn flat dense type="submit" :loading="isLoading" class="login-form__submit-btn" label="Sign In" no-caps />
        </q-form>

        <!-- Divider and Google login temporarily hidden -->
        <!-- 
        <div class="login-form__separator">
          <span>OR</span>
        </div>

        <q-btn class="login-form__google-btn" flat no-caps>
          <img src="@/assets/google-icon.svg" class="login-form__google-icon" alt="Google" />
          <span>Sign in with Google</span>
        </q-btn>
        -->

        <div class="login-form__signup">
          <span>Don't have an account? </span>
          <router-link :to="{ name: 'authRegister' }" class="login-form__signup-link"> Sign up </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthApi } from '@api/index';

export default {
  name: 'LoginForm',

  data() {
    return {
      // Form credentials
      credentials: {
        email: '',
        password: ''
      },
      // Error message for login failures
      error: null,
      // Loading state for submit button
      isLoading: false,
      // Toggle for password visibility
      showPassword: false
    };
  },

  methods: {
    /**
     * Handles the login form submission
     * Authenticates user credentials and redirects on success
     * Displays error message on failure
     * @async
     */
    async loginWithCredentials() {
      this.isLoading = true;
      this.error = null;

      try {
        await AuthApi.login(this.credentials.email, this.credentials.password);

        // Redirect to the requested page or home
        const redirectUrl = this.$route.query.redirect || '/';
        this.$router.push(redirectUrl);
      } catch (error) {
        console.error('Login error:', error);
        this.error = error.response?.data?.error_description || 'Invalid email or password. Please try again.';
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Validates email format using regex pattern
     * @param {string} email - Email address to validate
     * @returns {boolean} True if email is valid or empty, false otherwise
     */
    isValidEmail(email) {
      if (!email) return true; // Let the required rule handle empty case
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(email);
    }
  },

  emits: ['forgot-password', 'show-signup']
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 80px;
  }

  &__container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--secondary-bg-color);
    padding: 32px;
    border-radius: 8px;

    @media (max-width: 768px) {
      border-radius: 0;
      padding: 32px 24px;
      min-height: calc(100vh - 80px);
      justify-content: flex-start;
      padding-top: 48px;
    }
  }

  // Welcome section
  &__welcome {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    align-items: flex-start;
  }

  &__welcome-header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__camera-emoji {
    font-size: 24px;
    line-height: 1;
  }

  &__welcome-title {
    @include mona-sans-font($size: 24px, $bolded: true, $color: var(--main-text-color));
    margin: 0;
    text-align: left;
  }

  &__welcome-subtitle {
    @include inter-font($size: 16px, $color: var(--secondary-text-color));
    margin: 0;
    text-align: left;
  }

  // Form elements
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  &__label {
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    text-align: left;
    margin-bottom: 2px;
  }

  &__error {
    border-radius: 4px;
    @include inter-font($size: 14px);
    margin-bottom: 8px;
  }

  &__input {
    width: 100%;

    :deep(.q-field__control) {
      height: 48px;
    }

    :deep(.q-field__native) {
      @include inter-font($size: 16px, $color: var(--main-text-color));
      padding-top: 0;
      padding-bottom: 0;
    }

    :deep(.q-field__label) {
      display: none; // Hide Quasar's internal label
    }

    :deep(.q-field__marginal) {
      color: var(--secondary-text-color);
    }

    &.q-field--focused {
      :deep(.q-field__control) {
        border-color: var(--main-btn-color);
      }
    }
  }

  &__forgot-password {
    text-align: right;
    margin: 4px 0 8px;
  }

  &__forgot-link {
    @include inter-font($size: 12px, $color: var(--secondary-text-color));
    text-decoration: none;
    transition: text-decoration 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  &__submit-btn {
    width: 100%;
    height: 48px;
    background-color: var(--main-btn-color);
    margin-top: 8px;
    @include inter-font($size: 16px, $bolded: true, $color: var(--main-text-color));

    &:hover {
      background-color: darken(#fecb30, 5%);
    }
  }

  /* Google login and separator styles commented out but kept for future use */
  /*
  &__separator {
    display: flex;
    align-items: center;
    margin: 16px 0;
    
    &:before,
    &:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid var(--grey-hr);
    }
    
    span {
      padding: 0 12px;
      @include inter-font($size: 12px, $color: var(--secondary-text-color));
    }
  }

  &__google-btn {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-hr);
    background-color: white;
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    
    &:hover {
      background-color: var(--light-grey-hr);
    }
  }

  &__google-icon {
    height: 18px;
    margin-right: 12px;
  }
  */

  // Sign up section
  &__signup {
    text-align: center;
    margin-top: 16px; // Adjusted spacing since there's no Google button now
    @include inter-font($size: 14px, $color: var(--secondary-text-color));
  }

  &__signup-link {
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
