<template>
  <span>
    {{ currentQuote.before }}
    <span class="login__highlight typing-effect" :class="{ 'typing-effect--empty': !displayedHighlight }">{{ displayedHighlight }}</span>
    {{ currentQuote.after }}
  </span>
</template>

<script>
export default {
  name: 'TypingAnimation',

  props: {
    quotes: {
      type: Array,
      required: true
    },
    typingSpeed: {
      type: Number,
      default: 150
    },
    deletingSpeed: {
      type: Number,
      default: 100
    },
    delayBetweenWords: {
      type: Number,
      default: 2000
    }
  },

  data() {
    return {
      displayedHighlight: '',
      currentWordIndex: 0,
      typingTimer: null,
      isDeleting: false
    };
  },

  computed: {
    currentQuote() {
      return this.quotes[this.currentWordIndex];
    }
  },

  mounted() {
    this.startTypingAnimation();
  },

  beforeUnmount() {
    clearTimeout(this.typingTimer);
  },

  methods: {
    startTypingAnimation() {
      this.typeEffect();
    },

    typeEffect() {
      const currentWord = this.currentQuote.highlight;
      const isComplete = !this.isDeleting && this.displayedHighlight === currentWord;
      const isDeletingComplete = this.isDeleting && this.displayedHighlight === '';

      // Set the speed based on whether we're typing or deleting
      const speed = this.isDeleting ? this.deletingSpeed : this.typingSpeed;

      // Clear existing timer
      clearTimeout(this.typingTimer);

      if (isComplete) {
        // Word is complete, wait before starting to delete
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
          this.isDeleting = true;
          this.typeEffect();
        }, this.delayBetweenWords);
      } else if (isDeletingComplete) {
        // Deletion is complete, move to next word
        this.isDeleting = false;
        this.currentWordIndex = (this.currentWordIndex + 1) % this.quotes.length;
        this.typeEffect();
      } else {
        // Update the displayed text
        if (this.isDeleting) {
          this.displayedHighlight = currentWord.substring(0, this.displayedHighlight.length - 1);
        } else {
          this.displayedHighlight = currentWord.substring(0, this.displayedHighlight.length + 1);
        }

        // Schedule the next update
        this.typingTimer = setTimeout(() => {
          this.typeEffect();
        }, speed);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login__highlight {
  color: var(--main-btn-color);
}

.typing-effect {
  position: relative;
  border-right: 2px solid var(--main-btn-color);
  display: inline-block;
  animation: blinkCursor 0.7s infinite;
  min-width: 2px;

  &--empty {
    padding-right: 2px;
  }
}

@keyframes blinkCursor {
  0%,
  100% {
    border-right-color: var(--main-btn-color);
  }
  50% {
    border-right-color: transparent;
  }
}
</style>
