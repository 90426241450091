<template>
  <div class="signup-form">
    <div class="signup-form__container">
      <div class="signup-form__content">
        <!-- Welcome section - more compact -->
        <div class="signup-form__welcome">
          <h2 class="signup-form__welcome-title">Create Account <span class="signup-form__key-emoji">👋</span></h2>
          <p class="signup-form__welcome-subtitle">Sign up to get started with Flashy</p>
        </div>

        <!-- Error message -->
        <q-banner v-if="error" class="signup-form__error bg-negative text-white">
          <template v-slot:avatar>
            <q-icon name="error" color="white" />
          </template>
          {{ error }}
        </q-banner>

        <!-- Signup form - optimized for space -->
        <q-form @submit.prevent="handleSignupRequest" class="signup-form__form" ref="signupForm">
          <!-- First Name and Last Name (Horizontal) -->
          <div class="signup-form__name-row">
            <!-- First Name -->
            <div class="signup-form__form-group signup-form__form-group--half">
              <label for="firstName" class="signup-form__label">First Name</label>
              <q-input dense outlined v-model="firstName" id="firstName" type="text" class="signup-form__input" hide-bottom-space :rules="[val => !!val || 'First name is required']" />
            </div>

            <!-- Last Name -->
            <div class="signup-form__form-group signup-form__form-group--half">
              <label for="lastName" class="signup-form__label">Last Name</label>
              <q-input dense outlined v-model="lastName" id="lastName" type="text" class="signup-form__input" hide-bottom-space :rules="[val => !!val || 'Last name is required']" />
            </div>
          </div>

          <!-- Email -->
          <div class="signup-form__form-group">
            <label for="email" class="signup-form__label">Email</label>
            <q-input
              dense
              outlined
              v-model="email"
              id="email"
              type="email"
              class="signup-form__input"
              hide-bottom-space
              :rules="[val => !!val || 'Email is required', val => isValidEmail(val) || 'Please enter a valid email address']"
            />
          </div>

          <!-- Password - with collapsible requirements that only show on focus -->
          <div class="signup-form__form-group">
            <label for="password" class="signup-form__label">Password</label>
            <q-input
              dense
              outlined
              v-model="password"
              id="password"
              :type="isPwdVisible ? 'text' : 'password'"
              class="signup-form__input"
              hide-bottom-space
              :rules="passwordRules"
              @focus="isPasswordFocused = true"
              @blur="isPasswordFocused = false"
            >
              <template v-slot:append>
                <q-icon :name="isPwdVisible ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwdVisible = !isPwdVisible" />
              </template>
            </q-input>

            <!-- Password requirements - compact version, only visible on focus -->
            <transition name="fade">
              <div v-if="isPasswordFocused || password.length > 0" class="signup-form__password-requirements">
                <div class="signup-form__requirements-list">
                  <div class="signup-form__requirement" :class="{ satisfied: hasMinLength }">8+ characters</div>
                  <div class="signup-form__requirement" :class="{ satisfied: hasUppercase }">1 uppercase</div>
                  <div class="signup-form__requirement" :class="{ satisfied: hasLowercase }">1 lowercase</div>
                  <div class="signup-form__requirement" :class="{ satisfied: hasNumber }">1 number</div>
                  <div class="signup-form__requirement" :class="{ satisfied: hasSpecial }">1 special (!@#$%^&*)</div>
                </div>
              </div>
            </transition>
          </div>

          <!-- Confirm Password -->
          <div class="signup-form__form-group">
            <label for="confirmPassword" class="signup-form__label">Confirm Password</label>
            <q-input
              dense
              outlined
              v-model="confirmPassword"
              id="confirmPassword"
              :type="isConfirmPwdVisible ? 'text' : 'password'"
              class="signup-form__input"
              hide-bottom-space
              :rules="[val => !!val || 'Please confirm your password', val => val === password || 'Passwords do not match']"
            >
              <template v-slot:append>
                <q-icon :name="isConfirmPwdVisible ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isConfirmPwdVisible = !isConfirmPwdVisible" />
              </template>
            </q-input>
          </div>

          <!-- Terms and Conditions with proper alignment -->
          <div class="signup-form__terms">
            <div class="signup-form__terms-container">
              <q-checkbox dense v-model="acceptTerms" size="md" class="signup-form__terms-checkbox" />
              <div class="signup-form__terms-text">
                By signing up, you agree to Flashy's
                <a href="https://www.flashy.ae/terms-and-conditions" target="_blank" rel="noopener noreferrer" class="signup-form__terms-link" @click.stop="openTerms">terms of use</a>
                and
                <a href="https://www.getflashy.com/privacy-policy" target="_blank" rel="noopener noreferrer" class="signup-form__terms-link" @click.stop="openPrivacyPolicy">privacy policy</a>.
              </div>
            </div>
          </div>

          <q-btn flat type="submit" :loading="isLoading" class="signup-form__submit-btn" :label="isLoading ? 'Creating Account...' : 'Create Account'" no-caps />
        </q-form>

        <div class="signup-form__back-to-login">
          <span>Already have an account? </span>
          <router-link :to="{ name: 'authLogin' }" class="signup-form__back-link">Log in</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthApi } from '@/api/modules/auth';

export default {
  name: 'SignupForm',

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
      isPwdVisible: false,
      isConfirmPwdVisible: false,
      isPasswordFocused: false,
      error: null,
      isLoading: false,
      location: '',
      locationLoading: false,
      locationData: null,
      usingIpFallback: false,
      passwordRules: [
        val => !!val || 'Password is required',
        val => val.length >= 8 || 'Password must be at least 8 characters',
        val => /[A-Z]/.test(val) || 'Password must contain at least one uppercase letter',
        val => /[a-z]/.test(val) || 'Password must contain at least one lowercase letter',
        val => /[0-9]/.test(val) || 'Password must contain at least one number',
        val => /[!@#$%^&*]/.test(val) || 'Password must contain at least one special character'
      ]
    };
  },

  computed: {
    // Password strength indicators
    hasMinLength() {
      return this.password.length >= 8;
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    hasLowercase() {
      return /[a-z]/.test(this.password);
    },
    hasNumber() {
      return /[0-9]/.test(this.password);
    },
    hasSpecial() {
      return /[!@#$%^&*]/.test(this.password);
    },

    // Password strength meter
    passwordStrength() {
      let strength = 0;
      if (this.hasMinLength) strength++;
      if (this.hasUppercase) strength++;
      if (this.hasLowercase) strength++;
      if (this.hasNumber) strength++;
      if (this.hasSpecial) strength++;
      return strength;
    }
  },

  mounted() {
    // Small delay to let the component fully render
    setTimeout(() => {
      this.detectLocation().catch(error => {
        console.log('Auto location detection failed:', error);
      });
    }, 1000);
  },

  methods: {
    /**
     * Detect user's location using browser geolocation API with IP-based fallback
     */
    async detectLocation() {
      this.locationLoading = true;
      try {
        let permissionStatus;
        let useIpFallback = false;

        if (navigator.permissions && navigator.permissions.query) {
          try {
            permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

            // If permission is denied, use IP-based fallback
            if (permissionStatus.state === 'denied') {
              useIpFallback = true;
              console.log('Geolocation permission denied. Using IP fallback.');
            }
          } catch (permErr) {
            console.error('Error checking geolocation permission:', permErr);
            useIpFallback = true;
          }
        } else {
          // If permissions API is not available, we'll try geolocation directly
          // and catch any errors to fall back to IP-based location
          console.log('Permissions API not available. Will try geolocation directly.');
        }

        if (!useIpFallback) {
          try {
            // Try to get location via AuthApi's getUserLocation which might use geolocation
            const locationData = await AuthApi.getUserLocation();
            this.locationData = locationData;
            this.usingIpFallback = false;

            // Format the location display
            if (locationData.city && locationData.country) {
              this.location = `${locationData.city}, ${locationData.country}`;
            } else {
              this.location = locationData.formattedAddress || locationData.country;
            }

            // Successfully got location, return early
            return;
          } catch (geoError) {
            console.error('Geolocation error:', geoError);
            // If geolocation fails, fall back to IP-based location
            useIpFallback = true;
          }
        }

        // If we need to use IP fallback
        if (useIpFallback) {
          console.log('Using IP-based location fallback');
          this.usingIpFallback = true;

          // Get location data from IP
          const ipLocationData = await this.getLocationFromIP();

          if (ipLocationData) {
            this.locationData = ipLocationData;

            // Format the location display
            if (ipLocationData.city && ipLocationData.country) {
              this.location = `${ipLocationData.city}, ${ipLocationData.country}`;
            } else {
              this.location = ipLocationData.formattedAddress || ipLocationData.country;
            }
          }
        }
      } catch (error) {
        console.error('Error detecting location:', error);

        let errorMessage = 'Could not detect your location. Using default location.';
        this.$q.notify({
          color: 'warning',
          message: errorMessage,
          position: 'top'
        });
      } finally {
        this.locationLoading = false;
      }
    },

    /**
     * Get user location based on IP address
     * @returns {Promise<Object>} Location data from IP
     */
    async getLocationFromIP() {
      try {
        const response = await fetch('https://ipwho.is/');
        if (!response.ok) throw new Error('Failed to fetch IP location');

        const data = await response.json();
        return {
          city: data.city,
          country: data.country,
          countryCode: data.country_code,
          formattedAddress: `${data.city}, ${data.region}, ${data.country}`,
          source: 'ipwhois'
        };
      } catch (error) {
        console.error('IPWHOIS error:', error);
        return null;
      }
    },

    /**
     * Handles the signup request
     * Validates form and submits to API
     * @async
     */
    async handleSignupRequest() {
      // Reset previous error
      this.error = null;

      // Validate form
      const isValid = await this.$refs.signupForm.validate();
      if (!isValid) {
        return;
      }

      // Check terms
      if (!this.acceptTerms) {
        this.error = 'You must accept the Terms of Use and Privacy Policy';
        return;
      }

      // Prepare signup data according to API format
      const signupData = AuthApi.prepareProfileInformation({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        company_name: `${this.firstName} ${this.lastName}`.trim(),
        contact_name: `${this.firstName} ${this.lastName}`.trim(),
        contact_email: this.email,
        industry: this.$t('INDUSTRY_LIST.OTHER')
      });

      if (this.locationData) {
        if (this.locationData.countryCode) {
          signupData.additionalInformations.country = this.locationData.countryCode.toUpperCase();
        }
        if (this.locationData.city) {
          signupData.additionalInformations.main_location = this.locationData.city;
        }
      } else {
        // If no location data is available, try one more time with IP only
        try {
          const lastChanceLocation = await this.getLocationFromIP();
          if (lastChanceLocation) {
            if (lastChanceLocation.countryCode) {
              signupData.additionalInformations.country = lastChanceLocation.countryCode.toUpperCase();
            }
            if (lastChanceLocation.city) {
              signupData.additionalInformations.main_location = lastChanceLocation.city;
            }
          }
        } catch (error) {
          console.error('Last chance location detection failed:', error);
        }
      }

      // Set loading state
      this.isLoading = true;

      try {
        // Call signup API
        const response = await AuthApi.register(signupData);

        // Check if the response is successful
        if (response.statusCode === 200 && (!response.errors || response.errors.length === 0)) {
          // Handle successful registration
          this.$q.notify({
            type: 'positive',
            message: 'Account created successfully!',
            position: 'top'
          });

          // Redirect to login page
          this.$router.push({ name: 'authLogin' });
        } else if (response.errors && response.errors.length > 0) {
          // Process errors to make them more user-friendly
          this.error = this.getUserFriendlyErrorMessage(response.errors);
        } else {
          this.error = 'Registration failed. Please try again.';
        }
      } catch (error) {
        console.error('Signup error:', error);

        if (error.errors && error.errors.length > 0) {
          this.error = this.getUserFriendlyErrorMessage(error.errors);
        } else if (error.message) {
          this.error = error.message;
        } else {
          this.error = 'An error occurred during registration. Please try again.';
        }
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Converts technical error messages to user-friendly messages
     * @param {Array} errors - Array of error messages from API
     * @returns {String} A user-friendly error message
     */
    getUserFriendlyErrorMessage(errors) {
      if (!errors || errors.length === 0) {
        return 'An error occurred during registration. Please try again.';
      }

      // Join errors into a single string for checking
      const errorStr = errors.join(' ');

      // Check for specific error patterns and provide user-friendly messages
      if (errorStr.includes('Password too weak')) {
        return 'Your password is too weak. Please include uppercase letters, lowercase letters, numbers, and special characters.';
      }

      if (errorStr.includes('user already exists') || errorStr.includes('The user already exists')) {
        return 'An account with this email already exists. Please use a different email or try logging in.';
      }

      if (errorStr.includes('Bad Request')) {
        // If it's just a generic bad request without other info
        if (errors.length === 1 && errors[0] === 'Bad Request') {
          return 'Please check your information and try again.';
        }

        // If the bad request has other info, remove the "Bad Request" part to make it cleaner
        return errors.filter(err => err !== 'Bad Request').join(', ');
      }

      // Default: return the original errors joined
      return errors.join(', ');
    },

    /**
     * Validates email format using regex pattern
     * @param {string} email - Email address to validate
     * @returns {boolean} True if email is valid, false otherwise
     */
    isValidEmail(email) {
      if (!email) return false;
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(email);
    },

    openTerms(event) {
      // Prevent the default behavior
      event.preventDefault();
      // Prevent the event from bubbling up to parent elements
      event.stopPropagation();
      // Open the terms page in a new tab
      window.open('https://www.flashy.ae/terms-and-conditions', '_blank', 'noopener,noreferrer');
    },

    openPrivacyPolicy(event) {
      // Prevent the default behavior
      event.preventDefault();
      // Prevent the event from bubbling up to parent elements
      event.stopPropagation();
      // Open the privacy policy page in a new tab
      window.open('https://www.getflashy.com/privacy-policy', '_blank', 'noopener,noreferrer');
    }
  },

  emits: ['back-to-login']
};
</script>

<style lang="scss" scoped>
/* Style remains unchanged */
.signup-form {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 80px;
  }

  &__container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--secondary-bg-color);
    padding: 24px;
    border-radius: 8px;

    @media (max-width: 768px) {
      border-radius: 0;
      padding: 24px 20px;
      min-height: calc(100vh - 80px);
      justify-content: flex-start;
    }
  }

  &__welcome {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  &__key-emoji {
    font-size: 20px;
    line-height: 1;
    margin-left: 4px;
  }

  &__welcome-title {
    @include mona-sans-font($size: 22px, $bolded: true, $color: var(--main-text-color));
    margin: 0;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__welcome-subtitle {
    @include inter-font($size: 14px, $color: var(--secondary-text-color));
    margin: 0;
    text-align: left;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__name-row {
    display: flex;
    gap: 16px;
    width: 100%;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;

    &--half {
      width: calc(50% - 8px);
    }
  }

  &__label {
    @include inter-font($size: 13px, $bolded: true, $color: var(--main-text-color));
    text-align: left;
    margin-bottom: 0;
  }

  &__input {
    width: 100%;

    :deep(.q-field__control) {
      height: 40px;
    }

    :deep(.q-field__native) {
      @include inter-font($size: 16px, $color: var(--main-text-color));
      padding-top: 0;
      padding-bottom: 0;
    }

    :deep(.q-field__label) {
      display: none;
    }

    :deep(.q-field__marginal) {
      color: var(--secondary-text-color);
    }

    &.q-field--focused {
      :deep(.q-field__control) {
        border-color: var(--main-btn-color);
      }
    }
  }

  &__terms {
    margin-top: 4px;

    &-container {
      display: flex;
      align-items: flex-start;
    }

    &-checkbox {
      transform: scale(0.9);
      position: relative;
      top: 1px;
    }

    &-text {
      @include inter-font($size: 14px, $color: var(--secondary-text-color));
      margin-left: 8px;
      text-align: left;
      line-height: 1.4;
    }
  }

  &__terms-link {
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__submit-btn {
    width: 100%;
    height: 44px;
    background-color: var(--main-btn-color);
    margin-top: 4px;
    @include inter-font($size: 15px, $bolded: true, $color: var(--main-text-color));

    &:hover {
      background-color: darken(#fecb30, 5%);
    }
  }

  &__back-to-login {
    text-align: center;
    margin-top: 12px;
    @include inter-font($size: 14px, $color: var(--secondary-text-color));
  }

  &__back-link {
    @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__error {
    border-radius: 4px;
    @include inter-font($size: 14px);
    padding: 8px 12px;
  }

  &__password-requirements {
    margin-top: 4px;
    text-align: left;
    padding: 6px 8px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }

  &__requirements-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0;
    margin: 0;
  }

  &__requirement {
    @include inter-font($size: 11px, $color: var(--secondary-text-color));
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 6px;
    border-radius: 4px;
    display: inline-block;

    &.satisfied {
      color: var(--main-text-color);
      background-color: rgba(254, 203, 48, 0.15);
    }
  }

  // Fade transition for password requirements
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

// Input field styles - making them more compact
:deep(.q-field__control) {
  height: 40px;
}

:deep(.q-field__native) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.q-checkbox) {
  padding: 0;
}
</style>
