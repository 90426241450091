<template>
  <section class="login">
    <!-- Mobile-only logo that appears at the top of the screen -->
    <img src="@/assets/logo.png" class="login__mobile-logo" alt="logo" />

    <div class="login__container">
      <!-- Left Panel - Advertising (hidden on mobile) -->
      <AdPanel />

      <!-- Right Panel - Forms -->
      <LoginForm v-if="!showResetForm && !showSignupForm" @forgot-password="showResetForm = true" />
      <ResetForm v-else-if="showResetForm" @back-to-login="showResetForm = false" />
      <SignupForm v-else />
    </div>
  </section>
</template>

<script>
// Updated import paths to include new SignupForm
import AdPanel from '@/components/auth/login/AdPanel.vue';
import LoginForm from '@/components/auth/login/LoginForm.vue';
import ResetForm from '@/components/auth/reset/ResetForm.vue';
import SignupForm from '@/components/auth/signup/SignupForm.vue';

export default {
  name: 'Login',
  components: {
    AdPanel,
    LoginForm,
    ResetForm,
    SignupForm
  },

  data() {
    return {
      showResetForm: false,
      showSignupForm: false
    };
  },

  computed: {
    // Determine if we're on the registration route
    isRegistrationRoute() {
      return this.$route.name === 'authRegister';
    }
  },

  created() {
    // Set initial form state based on route
    this.showSignupForm = this.isRegistrationRoute;
  },

  watch: {
    // Watch for route changes to update form display
    $route(to) {
      this.showSignupForm = to.name === 'authRegister';
      this.showResetForm = false; // Always reset the password form on route change
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: var(--main-bg-color);
  position: relative;

  // Mobile-only logo that sits outside any panels
  &__mobile-logo {
    display: none;
    height: 40px;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 10;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0;
    }
  }
}
</style>
