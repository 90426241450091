<template>
  <div class="ad-panel">
    <div class="ad-panel__content">
      <img src="@/assets/logo.png" class="ad-panel__logo" alt="logo" />

      <div class="ad-panel__main-content">
        <div class="ad-panel__headline">
          <h1 class="ad-panel__title">
            <TypingAnimation :quotes="quotes" />
          </h1>
          <p class="ad-panel__subtitle">A smarter workspace for content-driven teams.</p>
        </div>

        <div class="ad-panel__features">
          <q-chip v-for="(feature, index) in features" :key="feature" :class="['ad-panel__feature-chip', `ad-panel__feature-chip--${chipColors[index % chipColors.length]}`]">
            {{ feature }}
          </q-chip>
        </div>

        <div class="ad-panel__gallery">
          <div class="ad-panel__image-container">
            <div class="ad-panel__image-wrapper">
              <q-skeleton v-if="!image1Loaded" type="rect" class="ad-panel__image ad-panel__image--skeleton" />
              <img src="@/assets/foodedit-min.jpeg" class="ad-panel__image" alt="Product photography" @load="image1Loaded = true" :style="{ display: image1Loaded ? 'block' : 'none' }" />
            </div>
            <div class="ad-panel__image-wrapper">
              <q-skeleton v-if="!image2Loaded" type="rect" class="ad-panel__image ad-panel__image--skeleton" />
              <img src="@/assets/2W0A5374-min.jpg" class="ad-panel__image" alt="Real estate photography" @load="image2Loaded = true" :style="{ display: image2Loaded ? 'block' : 'none' }" />
            </div>
            <div class="ad-panel__image-wrapper">
              <q-skeleton v-if="!image3Loaded" type="rect" class="ad-panel__image ad-panel__image--skeleton" />
              <img src="@/assets/DSC07355-min.jpg" class="ad-panel__image" alt="Food photography" @load="image3Loaded = true" :style="{ display: image3Loaded ? 'block' : 'none' }" />
            </div>
          </div>
        </div>
      </div>

      <div class="ad-panel__partners">
        <p class="ad-panel__partners-title">Trusted by thousands of brands worldwide</p>
        <div class="ad-panel__partner-logos">
          <img src="@/assets/uber.svg" alt="Uber" class="ad-panel__partner-logo ad-panel__partner-logo--uber" />
          <img src="@/assets/sephora.svg" alt="Sephora" class="ad-panel__partner-logo" />
          <img src="@/assets/IHG.svg" alt="IHG" class="ad-panel__partner-logo" />
          <img src="@/assets/mohg.svg" alt="MOHG" class="ad-panel__partner-logo" />
          <img src="@/assets/rclogo.svg" alt="RC Logo" class="ad-panel__partner-logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Updated import path for TypingAnimation
import TypingAnimation from './TypingAnimation.vue';

export default {
  name: 'AdPanel',
  components: {
    TypingAnimation
  },
  data() {
    return {
      // Array of quotes for typing animation
      quotes: [
        {
          before: 'Ready to',
          highlight: 'create',
          after: 'stunning content?'
        },
        {
          before: 'Ready to',
          highlight: 'share',
          after: 'stunning content?'
        },
        {
          before: 'Ready to',
          highlight: 'edit',
          after: 'stunning content?'
        },
        {
          before: 'Ready to',
          highlight: 'manage',
          after: 'stunning content?'
        }
      ],
      // Colors for feature chips
      chipColors: ['yellow', 'blue', 'purple', 'green'],
      // Feature list displayed in ad panel
      features: ['Social Media Tools', 'AI Editing Tools', 'Workflow Integrations', 'Content Management'],
      image1Loaded: false,
      image2Loaded: false,
      image3Loaded: false
    };
  }
};
</script>

<style lang="scss" scoped>
.ad-panel {
  width: 65%;
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-color: var(--main-bg-color);

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    display: none; // Hide the ad panel on mobile
  }

  &__content {
    width: 100%;
    height: 100%;
    max-width: 700px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    padding: 48px 0;
    justify-content: space-between;
  }

  &__logo {
    height: 50px;
    width: auto;
    min-width: 120px;
    align-self: flex-start;
    margin-bottom: 16px;
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    margin-bottom: 16px;
  }

  &__headline {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    width: 100%;
  }

  &__title {
    @include mona-sans-font($size: 36px, $bolded: true, $color: var(--primary-text-color));
    text-align: left;
    margin: 0;
    line-height: 1.2;
  }

  &__subtitle {
    @include inter-font($size: 16px, $color: var(--secondary-text-color));
    text-align: left;
    margin: 0;
  }

  // Features section
  &__features {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    padding-left: 0;
    align-self: stretch;
    width: 100%;
  }

  &__feature-chip {
    @include mona-sans-font($size: 13px, $bolded: true);
    padding: 6px 12px;
    border-radius: 12px;
    margin-left: 0;
    height: auto;

    ::v-deep .q-chip {
      margin: 0;
      min-height: 26px;
    }

    &--yellow {
      background-color: rgba(254, 213, 89, 0.15);
      color: darken(#fed559, 30%);
    }

    &--blue {
      background-color: rgba(52, 152, 219, 0.15);
      color: darken(#3498db, 20%);
    }

    &--purple {
      background-color: rgba(91, 45, 144, 0.15);
      color: darken(#5b2d90, 10%);
    }

    &--green {
      background-color: rgba(46, 204, 113, 0.15);
      color: darken(#2ecc71, 20%);
    }
  }

  // Gallery section
  &__gallery {
    flex-grow: 1;
    height: auto;
    min-height: 220px;
    width: 100%;
    padding: 8px 0 16px 0;
    margin: 0 0 8px 0;
    align-self: stretch;
  }

  &__image-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    height: 100%;
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    min-height: 120px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid var(--light-grey-hr);
    background-color: rgba(0, 0, 0, 0.05); /* Placeholder background */
  }

  &__image--skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  // Partners section
  &__partners {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  &__partners-title {
    @include mona-sans-font($size: 16px, $bolded: true, $color: var(--primary-text-color));
    text-align: left;
    margin: 0;
  }

  &__partner-logos {
    margin-top: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 30px;
  }

  &__partner-logo {
    width: auto;
    height: 40px;
    max-height: 40px;
    min-width: 50px;
    max-width: 180px;
    opacity: 0.9;
    object-fit: contain;
    transition: opacity 0.2s ease;
    background-color: rgba(0, 0, 0, 0.02); /* Subtle placeholder */

    &:hover {
      opacity: 1;
    }

    &--uber {
      max-width: 70px;
    }
  }
}
</style>
